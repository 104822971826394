<template>
  <van-form @submit="onSubmit">
    <van-cell-group title=" " inset>
      <van-field
        v-model="phone"
        name="phone"
        label="手机号"
        placeholder="请输入已注册的手机号"
      />
      <van-field
        v-model="name"
        name="name"
        label="名称"
        placeholder="请输入"
      />
      <van-field name="uploader" label="头像">
        <template #input>
          <van-uploader
            v-model="fileList"
            :max-count="1"
            :max-size="10 * 1024 * 1024"
            :after-read="afterRead"
          />
        </template>
      </van-field>
    </van-cell-group>
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">
        提交
      </van-button>
    </div>
    <div v-if="!isWechatOpenid" style="padding: 16px;">
      <div style="font-size: 16px; font-weight: bold; color: red;">错误提示：</div>
      <p style="margin-top: 10px; font-size: 14px;">1、未绑微信会导致该用户收益无法提现。</p>
      <p style="margin-top: 5px; font-size: 14px;">2、如本店已申请二级商户，未绑微信会导致该用户收不到收益。</p>
      <p style="margin-top: 5px; font-size: 14px;">3、未绑微信请先让该用户使用自己的微信登录一下本店商城。</p>
    </div>
  </van-form>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'
import { upload } from '@/util/util'

export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRoute = inject('useRoute')
    const useRouter = inject('useRouter')
    const state = reactive({
      phone: '',
      buyerId: '',
      userId: '',
      name: '',
      avatar: '',
      fileList: [],
      show: false,
      storeId: Cookies.get('storeId'),
      qiniu: {},
      store: {},
      type: useRoute.query.type || 0,
      isWechatOpenid: true
    })
    const onSubmit = (values) => {
      if (values.uploader.length === 0) {
        Toast('请上传一个图片')
        return false
      }

      state.avatar = values.uploader[0].url
      delete values.uploader
      Toast.loading({ forbidClick: true, duration: 0 })
      post('/user.get', {
        phone: state.phone
      }).then(res => {
        if (res.code === 0) {
          if (!res.data.wechatOpenid) {
            state.isWechatOpenid = false
            Toast('该用户未绑定微信')
            return
          }
          post('/staff.create', {
            userId: res.data.userId,
            storeId: state.storeId,
            name: state.name,
            avatar: state.avatar,
            type: parseInt(state.type),
            sale: 0
          }).then(res => {
            if (res.code === 0) {
              post('/addReceivers', {
                staffId: res.data.id,
                storeId: state.storeId
              }).then(res => {
                if (res.code === 0) {}
              })
              Toast.success()
              useRouter.go(-1)
            } else {
              Toast(res.msg)
            }
          })
        } else {
          Toast(res.msg)
        }
      })
    }
    const beforeRead = (file) => {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif') {
        Toast('图片类型支持: jpg,png,gif')
        return false
      }
      return true
    }
    const afterRead = (file) => {
      upload({
        fileBlob: file.file,
        token: state.qiniu.token,
        domain: state.qiniu.domain
      }).then(res => {
        file.url = res.img
        console.log(res.img)
      })
    }
    const init = () => {
      post('/util.getQiniuToken', {
      }).then(res => {
        state.qiniu = res.data
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSubmit,
      beforeRead,
      afterRead
    }
  }
}
</script>

<style scoped>
</style>
